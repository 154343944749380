// src/BusinessCard.tsx

import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

type BusinessCardProps = {
    name: string;
    title: string;
    email_main: string;
    email: string;
    phone: string;
    imageUrl: string;
};

const BusinessCard: React.FC<BusinessCardProps> = ({ name, title, email_main, email, phone, imageUrl }) => {
    return (
        <Card sx={{ maxWidth: 460, height: 340, display: 'flex', flexDirection: 'column', boxShadow: '10px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="165"
                    image={imageUrl}
                    alt={`${name}'s picture`}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {email_main}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {phone}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default BusinessCard;
