import Nav from './components/Nav';
import WorkCard from './components/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import TimeLineComponent from './components/Timeline';
import { Typography } from '@mui/material';
import Footer from './components/Footer';
import TextEditor from './components/NotePad';
import BusinessCard from './components/BizCard';


function App() {
  return (
    <Box sx={{ color: 'black', minHeight: '100vh', bgcolor: "#fffdf8" }}>
      <Box mt={5}>
        <Nav />
      </Box>

      <Typography variant="h3" align="center" sx={{ mt: 5 }}>Works & Projects</Typography>
      <Grid container spacing={4} margin={5}>
        <Grid xs={12} sm={6} md={4}>
          <BusinessCard
            name="池田 有之介"
            title="フルスタックエンジニア/通訳(日英)/大学院生"
            email_main="main: m242128@hiroshima-u.ac.jp"
            email='sub: ikeda042@umn.edu'
            phone="070-1947-1305"
            imageUrl="logo512.png"
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <WorkCard image="card2.png" title="一細胞解析ソフトウェアPhenoPixel4.0" description='顕微鏡データから細胞情報を高速に抽出し、研究員がいない夜間に全自動でデータ解析を行います。' github_link='https://github.com/ikeda042/PhenoPixel4.0' site_link='' />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <WorkCard image="pheno5.png" title="一細胞解析API PhenoPixel5.0" description='PhenoPixel4.0のデータ解析をWEB上で使用できるようにAPIを展開しました。計算は全てクラウドで行います。' github_link='https://github.com/ikeda042/PhenoPixel5.0' site_link='https://phenopixel5.site/' />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <WorkCard image="card5.png" title="HirodaiMaps" description='バリアフリー機能および高速な経路案内機能Rapid travelシステムを搭載したオンライン地図を展開しました。広島大学が開催するビジネスコンテストで入賞しました。(2023年度)' github_link='' site_link='https://www.hiroshima-u.ac.jp/iagcc/news/80636' />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <WorkCard image="card3.png" title="勉強まとめブログ" description='研究や仕事で学んだことをまとめて行っている個人ブログです。月間4万PV達成。' github_link='' site_link='https://japbros-poco.main.jp/' />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <WorkCard image="card4.png" title="Ellipsys Inc." description='CTO/フルスタックエンジニアとして働いています。' github_link='' site_link='https://ellipsys.co.jp/works.html' />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <WorkCard image="card1.png" title="汎用データ解析スクリプト" description='研究用に作成したデータ解析およびアルゴリズムのスクリプトをまとめたレポジトリです。' github_link='https://github.com/ikeda042/GeneralDataAnalyses' site_link='' />
        </Grid>
      </Grid >
      {/* <Typography variant="h3" align="center" sx={{ mt: 5 }}>Can & Can't do</Typography>
      <Grid container spacing={0} margin={5}>
        <Grid xs={6} sm={6} md={6}>
          <TextEditor items={["1."]} title={"できること"} />
        </Grid>
        <Grid xs={6} sm={6} md={6}>
          <TextEditor items={["1."]} title={"できないこと"} />
        </Grid>
      </Grid> */}
      <Typography variant="h3" align="center" sx={{ mt: 5 }}>History</Typography>
      <Grid container spacing={0} margin={5}>
        <Grid xs={12} sm={12} md={8}>
          <TimeLineComponent />
        </Grid>
      </Grid>
      <Footer />
    </Box>

  );
}

export default App;
